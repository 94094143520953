<template>
    <div class="newReserve">
        <rxNavBar v-if="fromType=='1'" title="新增预定" androidOrIOSFlag="true"></rxNavBar>
        <rxNavBar v-if="fromType!='1'" title="新增预定" ></rxNavBar>
        <!--上传图片loading-->
        <loading v-if="loadingFlag"></loading>
        <div class="reviews">
            <!--租房列表-->
            <div class="review">
                <div class="title" v-for="(item,index) in roomHouseList" :key="index">
                    <!--图片部分-->
                    <div class="relation">
                        <div class="relation"><img :src="item.path!=undefined&&item.path!=''?item.path:errorImg" class="relationImage"/></div>
                    </div>
                    <!--文字部分-->
                    <div>
                        <div class="hidden">
                            <div>{{item.roomDetailedAddress}}</div>
                        </div>
                        <div>
                            <span class="fnotRed"></span>
                        </div>
                    </div>
                </div>
<!--                <div class="panelInfo">-->
<!--                    <p  class="pane lInfoP">预定时租金：<span class="p_span">{{rentMoney}}元</span></p>-->
<!--                    <p class="panelInfoP">租期：<span class="p_span">{{rentTimeLong}}个月</span></p>-->
<!--                </div>-->
            </div>
            <!--                客户信息 可以修改-->
            <div v-if="formValidate.renterNameAuthentication!='1'">
                <div class="customer"><span>客户信息</span><span class="customerSpan">(可改)</span></div>
                <div class="part-inputpart">

                    <!--手机号-->
                    <div class="part-inputpart-row part-inputpart-row-shortA" v-if="roomDisabled=='user'">
                        <span :class=" !mobile ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">手机号</span>
                        <div class="content-divide">|</div>
                        <van-field type="text" :formatter="formatter" oninput="value=value.replace(/[^\d]/g,'')" v-model="mobile" :readonly="formValidate.mobileState" :disabled="formValidate.mobileState"
                                   maxlength="11" placeholder="请输入手机号"   class="username" center  />
                    </div>

                    <div class="part-inputpart-row part-inputpart-row-shortA" @click="clickModileShow" id="clickModileShow" v-else>
                        <span :class=" !mobile ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">手机号</span>
                        <span class="content-divide">|</span>
                        <van-field type="text" placeholder="请输入手机号"  :formatter="formatter"  v-model="mobile"  class="" maxlength="11"
                                   :readonly="formValidate.mobileState" :disabled="formValidate.mobileState"  center :border="borderType"/>
                        <div class="youjiantouImgDiv"><img src="../../../assets/images/youjiantou.png" class="youjiantou"></div>
                    </div>
                    <!--姓名-->
                    <div>
                        <div class="part-inputpart-row part-inputpart-row-short">
                            <div :class=" 0==ownerInfo.name.trim().length? 'content-none' : 'content-have'"></div>
                            <span class="part-inputpart-row-header">姓名</span>
                            <span class="content-divide">|</span>
<!--                            <input v-model="ownerInfo.name" class="username" :disabled="false">-->
                            <van-field type="text" :formatter="formatter"  v-model="ownerInfo.name" :readonly="formValidate.nameState" :disabled="formValidate.nameState"
                                       placeholder="请选择"   class="username" center />
                        </div>
                        <div class="part-inputpart-row-sex">
                            <span class="part-inputpart-man part-inputpart-row-header" :class="'1'==ownerInfo.sex ? 'part-inputpart-row-redtext' : 'part-inputpart-row-short-graytext' " @click="mansex">男</span>
                            <span class="content-divide content-divide-position part-inputpart-row-short-graytext">|</span>
                            <span class="part-inputpart-woman part-inputpart-row-header" :class="'0'==ownerInfo.sex ? 'part-inputpart-row-redtext' : 'part-inputpart-row-short-graytext' " @click="womansex">女</span>
                        </div>
                    </div>
        <!--                身份证号码-->
                    <div id="cohabitingPersonCardPanel" class="part-inputpart-row">
                        <span :class="''!=certificateTitle ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header" @click="clickCoOwnerCardShow">{{certificateTitle!= ''?certificateTitle:'身份证'}}</span>
                        <img :class=" CardShowImage ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                             src="../../../assets/images/triangle.png"  @click="clickCoOwnerCardShow"/>
                        <span class="content-divide">|</span>
                        <van-field  :formatter="formatter" v-model="certificateNumber" placeholder="填写证件号码" class="username" maxlength="18" @blur="animateOwnerIdCard" />
                    </div>

                    <div>
                        <van-popup v-model="isCoOwnerCardShow" position="bottom" @closed="closeCoOwnerCardShow">
                            <van-picker show-toolbar value-key="dictionaryTitle" :columns="certificateTypeArr" default-index="0"
                                        @cancel="isCoOwnerCardShow = false" @confirm="selectCoOwnerCard"  />
                        </van-popup>
                    </div>



                </div>
            </div>

            <!--                客户信息 不可改-->
            <div v-if="formValidate.renterNameAuthentication==1">
                <div class="customera"><span>客户信息</span><span class="customerSpan">(已认证)</span></div>
                <div class="review">
<!--                    不可再次点击，方法已隐藏chooseUser-->
                    <div class="tenant">
                        <div class="tenantUsername">
                            <div class="gender"><span class="genderTitle">租客:</span>{{formValidate.renterName}}</div>
                            <div class="gender"><span class="genderTitle">性别:</span>{{formValidate.renterGender}}</div>
                        </div>
                        <div><span class="genderTitle">手机:</span>{{mobile}}</div>
                        <div v-for="(certificate,index) in certificateTypeArr" :key="index" v-if="certificate.dictionaryValue==formValidate.renterCertificateType"><span class="genderTitle">{{certificate.dictionaryTitle}}:</span>{{formValidate.renterCertificateCode}}</div>
                    </div>
                </div>
            </div>

            <!--共有权人证件信息-->
            <div class="part-inputpart">
                <div class="part-inputpart-cardpic">
                    <!--                    <div class="papers">-->
                    <!--                        <span class="">证件凭证</span>-->
                    <!--                    </div>-->
                    <div class="part-inputpart-cardpic-module-1">
                        <div class="part-cardpic-module">
                            <div class="part-inputpart-cardpic-pic">
                                <img v-show="frontOfCard"
                                     :src="frontOfCard" @click="changUpload('commonOwnerFrontOfCard')"/>
                            </div>
                            <van-uploader v-show="!frontOfCard" :preview-image="false" ref="commonOwnerFrontOfCard" :before-read="beforeRead"
                                          :after-read="afterReadCoOwnerCertificateFront" upload-icon="plus" :max-count="1" image-fit="contain">
                                <div class="part-inputpart-cardpic-button"></div>
                            </van-uploader>
                            <div class="part-inputpart-cardpic-text">身份证正面</div>
                        </div>
                        <div class="part-cardpic-module">
                            <div class="part-inputpart-cardpic-pic">
                                <img v-show="reverseOfCard"
                                     :src="reverseOfCard" @click="changUpload('commonOwnerReverseOfCard')"/>
                            </div>
                            <van-uploader v-show="!reverseOfCard" :preview-image="false" ref="commonOwnerReverseOfCard" preview-full-image :before-read="beforeRead"
                                          :after-read="afterReadCoOwnerCertificateBack" upload-icon="plus" :max-count="1">
                                <div class="part-inputpart-cardpic-button"></div>
                            </van-uploader>
                            <div class="part-inputpart-cardpic-text">身份证反面</div>
                        </div>
                        <div class="part-cardpic-module">
                            <div class="part-inputpart-cardpic-pic">
                                <img v-show="holdingCard"
                                     :src="holdingCard"  @click="changUpload('yzHoldCard')"/>
                            </div>
                            <van-uploader v-show="!holdingCard" :preview-image="false" ref="yzHoldCard" :before-read="beforeRead"
                                          :after-read="afterReadCertificateHandHeld" upload-icon="plus" :max-count="1">
                                <div class="part-inputpart-cardpic-button"></div>
                            </van-uploader>
                            <div class="part-inputpart-cardpic-text">
                                手持身份证
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!--                预定信息-->
            <div class="customera"><span>预定信息</span></div>
            <div class="review">
                <div class="borderStyle">
                    <div class="part-inputpart-row-shortA ">
                        <span :class=" !earnestMoney ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">定金</span>
                        <span class="content-divide">|</span>
                        <input type="number" placeholder="请选择"   class="username" v-model="earnestMoney"/>
                        <span class="part-inputpart-row-header-pay">元</span>
                    </div>

                    <div class="changeType  rentTimeLongClass" @click="clickRentTimeShow()">
                        <span :class=" !rentTimeLong ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">租期</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"
                                :class="rentTimeLong!=''? 'part-inputpart-row-normaltext':''">{{rentTimeLong}}</span>
                        <img class="part-inputpart-row-right"
                                :class=" isRentTimeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                                src="../../../assets/images/triangle.png" />
                        <span class="part-inputpart-row-header-pay">月</span>
                    </div>
                    <!-- <div class="part-inputpart-row" @click="clickRentTimeShow(index)">
                        <span class="part-inputpart-row-header notImport">租期</span>
                        <span class="content-divide">|</span>
                        <span :class="item.rentTimeLong?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{item.rentTimeLong||'请选择'}}</span>
                        <img class="part-inputpart-row-right"
                            :class="isRentTimeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                            src="../../assets/images/triangle.png">
                    </div> -->
                    <van-popup v-model="isRentTimeShow" position="bottom">
                        <van-picker show-toolbar value-key="dictionaryValue" :columns="rentTimeLongList" @cancel="isRentTimeShow = false"
                            @confirm="selectRentTime"/>
                    </van-popup>
                    <div class="changeType borderType">
                        <div :class="earnestMoneyInvalidTime=='' ? 'content-none' : 'content-have'"></div>
                        <div class="pOne">定金失效时间</div>
                        <div class="content-divide">|</div>
                        <span class="vanCell" @click="houseChildTypeName=='配置中'?show = true:show = false">{{showEarnestMoneyInvalidTime}}</span>
                        <img class="changeTypeImg" src="../../../assets/images/calendar.png" @click="houseChildTypeName=='配置中'?(show = true):(show = false)"/>
<!--                            <van-popup v-model="show" position="bottom"  :style="{ height: '70%' }">-->
<!--                                <van-calendar v-model="show"-->
<!--                                              :poppable="false"-->
<!--                                              :show-confirm="false"-->
<!--                                              @confirm="onConfirm"/>-->
<!--                            </van-popup>-->

                    </div>
                    <van-popup v-model="show" position="bottom" class="selectTime">
                        <div class="date-dropDownList" >
                            <van-datetime-picker type="date" :min-date="minDate" title="选择年月日" item-height="35px" @confirm="onConfirm" @cancel="cancel"/>
                        </div>
                    </van-popup>
                    <div class="changeCause">
                        <div class="textareaTitle">
                            <div class="content-noneAA"></div>
                            <div class="pOne">备注</div>
                            <span style="font-size: 10px;margin: 5px 0px 0px 10px">(非必填)</span>
                        </div>
                        <textarea class="textareaInput" v-model="remarks" placeholder="您遇到了什么问题?"></textarea>
                    </div>
                </div>
                </div>
            <div class="customera"><span>房屋收订人</span></div>
                <div class="review">
                    <div class="borderStyle">
                        <div class="part-inputpart-row-shortA " @click="clickSignatoryShow" id="signatoryPanel">
                            <span :class=" !staffName ? 'content-none' : 'content-have' "></span>
                            <span class="part-inputpart-row-header">签约人</span>
                            <span class="content-divide">|</span>
                            <input type="text" placeholder="请选择" v-model="staffName"  class="username" />
                            <span class="youjiantouImg"><img src="../../../assets/images/youjiantou.png" class="youjiantou"></span>
                        </div>
                        <div>
                            <div class="part-inputpart part-inputpart-dropDownList" style="height:40%"
                                 v-if="isSignatoryShow">
                                <treeSelect class="part-inputpart-dropDownList-option" :itemlist="signatoryArr"
                                            placeholder="请选择" nodatatext="没有找到" :show="isSignatoryShow"
                                            @item-click="selectSignatoryOption"></treeSelect>
                            </div>
                        </div>

                        <div class="changeType borderType" @click="clickPartnerShow" id="partnerPanel">
                            <span class="site-span"></span>
                            <span class="part-inputpart-row-header">合作人</span>
                            <span class="content-divide">|</span>
                            <input type="text" placeholder="请选择" v-model="label"  class="username" />
                            <span class="youjiantouImg"><img src="../../../assets/images/youjiantou.png" class="youjiantou"></span>
                        </div>
                        <div>
                            <div class="part-inputpart part-inputpart-dropDownList" style="height:40%"
                                 v-if="isPartnerShow">
                                <treeSelect class="part-inputpart-dropDownList-option" :itemlist="partnerArr"
                                            placeholder="请选择" nodatatext="没有找到" :show="isPartnerShow"
                                            @item-click="selectPartnerOption"></treeSelect>
                            </div>
                        </div>
                        <div id="bankTypeSelect" class="part-inputpart-row-shortA" @click="showOrHiddenOfBankType" v-if="label">
                            <span  :class="normaltext==''?'content-none':'content-have'" ></span>
                            <span class="part-inputpart-row-header">合作占比</span>
                            <span class="content-divide">|</span>
                            <span class="part-inputpart-row-graytext"
                                  :class="normaltext!=''? 'part-inputpart-row-normaltext':''">{{normaltext}}</span>
                            <img class="part-inputpart-row-right"
                                 :class=" isBankTypeSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                                 src="../../../assets/images/triangle.png" />
                        </div>
                            <van-popup v-model="isBankTypeSelect" position="bottom">
                                <van-picker show-toolbar value-key="dictionaryTitle"  :columns="contractRaito"
                                            @cancel="isBankTypeSelect = false" @confirm="selectBankType" />
                            </van-popup>
                        <div>

                        </div>
                    </div>
                </div>
        </div>
        <div class="part part-button" @click="save()? formSubmit():''" :class="save()? 'part-button-enabled' : '' " :disabled="isDisable">{{saveFlag?'保存':'保存中'}}</div>
    </div>
</template>
<script>
    import {
        NavBar,
        DatetimePicker,
        Uploader,
        Tag,
        Calendar,
        Picker,
        Popup,
        Search,
        Field
    } from 'vant'
    import {
      getQueryBaseData, base64Upload, queryStaffList, percontractsaveAndUpdatePerContractList,queryMonth,
      getStaffFuntionModelList, findUserInfoByMobile, getRoomHouseListById, findRentByRoomId, getPerRoomHouseList
    } from "../../../getData/getData";
    import {responseUtil, getStaffId, checkAndroid, checkIOS, openInWebview} from "../../../libs/rongxunUtil";
    import util from "../util";
    import config from "../config";
    import treeSelect from "../../../components/treeSelect/treeSelect";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus";

    //调用android关闭页面方法*******begin*********
    function backToAndroid(){
        window.himi.closeWindow();
    }

    export default {
        name: "newReserve",
        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [Search.name]:Search,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Tag.name]: Tag,
            [Calendar.name]: Calendar,
            [Field.name]: Field,
            treeSelect,
            rxNavBar,
        },
        data() {
            return {
                fromType:'',  //区别原生跳转还是H5跳转
                borderType:false,
                saveFlag:true,
                only:true,
                border:false,
                loadingFlag:false,//上传图片loading
                roomDetailedAddress:'',//地址
                roomPhoto:'',
                rentMoney:'',//租金
                houseChildTypeName:'',//房间状态
                rentTimeLong:'12',//租期
                userName:'',//租客姓名
                mobile:'',//租客电话
                gender:'',//性别
                contractId:'',//合同id
                roomHouse_id:'',//间房源
                addStaff_id:'',//创建人员工id
                signStaff_id:'',//签约人员工id
                partnerStaffId:'',//合作人员工id
                partnerRatioId:'',//合作占比id
                signDepartment_id:'',//签约人部门id
                partnerDepartment_id:'',//合作人部门id
                earnestMoney:'',//定金
                minDate: new Date(),
                earnestMoneyInvalidTime:'',//定金失效时间
                showEarnestMoneyInvalidTime:'',//定金失效时间(显示用)
                remarks:'',//备注
                potentialResource_id:'',// 潜在资源Id
                frontOfCard:'',//身份证正面
                reverseOfCard :'',//身份证反面
                holdingCard :'',//手持身份证
                certificateCode:'',//租客证件号码
                certificateTypeArr:[],//证件类型
                contractRaito:[],//合同占比
                certificateType:'0',//证件类型
                certificateTitle:'',//证件名称
                certificateNumber:'',//证件号码
                CardShowImage:false,//身份证按钮状态
                signatoryArr:[],//签约人数组
                partnerArr: [],//合作人数组
                staffName:'',//签约人姓名
                label:'',//合作人姓名
                roomHouseList:[],
                roomDisabled:'',//潜客页面标志
                userListInfo:[],//承担人
                //签约人
                isSignatoryShow: false,
                isSignatorySelected: false,
                //合伙人
                isPartnerShow: false,
                isPartnerSelected: false,
                //合伙占比
                isPartnershipProportionShow: false,
                isPartnershipProportionSelected: false,
                isCoOwnerCardShow: false,
                errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
                normaltext:'',//所属银行类型
                isBankTypeSelectAready: false,
                isBankTypeSelect: false,
                isShow:false,
                isWait:false,
                isAll:true,
                isExpectedDateShow: false,
                isExpectedDateShow01: false,
                isPotentialHousingTypeShow: false,
                isPotentialHousingTypeSelected: false,
                show:false,
                text:'',
                isFixed: false,
                //显示 哪个信息
                whichInfo: '0',
                //证件
                fdName: [
                    'CERTIFICATETYPEMAP'
                ],
                wtrFrontOfCard: require('../../../assets/images/default_img_4.png'), //身份证前面
                wtrReverseOfCard: require('../../../assets/images/default_img_5.png'), //身份证后面
                entrustment: require('../../../assets/images/default_img_3.png'), //业主委托
                holdEntrustment: require('../../../assets/images/default_img_6.png'), //业主手持委托
                isCardShow: false,
                isCardSelected: false,
                isCohabitingPersonCardShow: false,
                isCohabitingPersonCardSelected: false,
                coOwner: {
                    //租客信息
                    name: "",
                    phone: "",
                    IdCard: "",
                    certificateType: {},
                    commonOwnerFrontOfCard: '', //身份证前面
                    commonOwnerReverseOfCard: '', //身份证后面
                    yzHoldCard:''//手持身份证
                },
                ownerInfo: {
                    phone: '',
                    name: '',
                    sex: '',
                    card: '',
                    cardNumber: '',
                    emergencyContactName: '',
                    emergencyContactPhone: '',
                    CertificateFront: '',
                    CertificateBack: '',
                    CertificateHandHeld: '',
                    //是否有同住
                    liveTogether: '',
                    money:'',
                    signerA:'',
                    signerB:''
                },
                //用户验证数据
                formValidate:{
                    renterNameAuthentication:'',
                    renterName:'',
                    renterGender:'',
                    renterCertificateType:'',
                    renterCertificateCode:'',
                    roomId: '',
                    mobileState : false,
                    sexState:false,
                    nameState:false,
                    certificateState:false,
                    rentTimeState:false,
                    renterMobile: '',
                    partnerStaffId: '',
                    signStaffId: '',
                    rentTimeLong: '',
                    partnerRatioId: '',
                    potentialResource_id: ''
                },
                isDisable:false,
                isRentTimeShow:false,  //租期下拉选
                rentTimeLongList:[],  //租期列表
                offerPrice:'',  //报价
                firstHousePrice:'',  //首次出房价
                priceIncreaseSchemeList:[],  //提价区间
                houseEndTime:'',  //房源截止时间
                rentTimeState:false  //是否可以修改,true不可修改
            }
        },
        //监听数值变化
        watch: {
            //监听手机号变化查询改手机号用户是否实名认证
            "mobile": function () {
                this.findUserInfoByMobile(this.mobile)
            },
        },

        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name == null){
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            next()
        },

        created(){
            this.getQueryBaseData()
            this.getRenterData()
            // 员工列表初始化
            this.initStaffData();
            this.getStaffFuntionModelList();
        },

        activated() {
            eventBus.$on('referrer', function(data){
                if(data.signPersonInfo){ //签约人
                    this.staffName= data.signPersonInfo.staffName;
                    this.signDepartment_id=data.signPersonInfo.department_id//签约人部门id
                    this.signStaff_id=data.signPersonInfo.id
                }
                if(data.cooperationPersonInfo){   //合作人
                    this.label = data.cooperationPersonInfo.staffName;
                    this. partnerDepartment_id=data.cooperationPersonInfo.department_id
                    this.partnerStaffId=data.cooperationPersonInfo.id
                }
                if (data.userListInfo) {
                    this.userListInfo=data.userListInfo
                    if (this.userListInfo.length!=0){ //选择完去后去验证
                        this.mobileChange(data.userListInfo.mobile);
                        this.mobile=data.userListInfo.mobile
                        this.potentialResource_id=data.userListInfo.pId
                        this.formValidate.renterMobile=data.userListInfo.mobile
                    }
                }
            }.bind(this));
        },
        //如果前往的页面不是推荐人，则摧毁缓存
        beforeRouteLeave:function(to, from, next){
            if (from && to.name != 'referrer')
            {//此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
                if (this.$vnode && this.$vnode.data.keepAlive)
                {
                    if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache)
                    {
                        if (this.$vnode.componentOptions)
                        {
                            var key = this.$vnode.key == null
                                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                                : this.$vnode.key;
                            var cache = this.$vnode.parent.componentInstance.cache;
                            var keys  = this.$vnode.parent.componentInstance.keys;
                            if (cache[key])
                            {
                                if (keys.length) {
                                    var index = keys.indexOf(key);
                                    if (index > -1) {
                                        keys.splice(index, 1);
                                    }
                                }
                                delete cache[key];
                            }
                        }
                    }
                }
                this.$destroy();
            }
            next();
        },

        mounted() {
            this.roomId=this.$route.query.roomId //房间id
            this.fromType=this.$route.query.type //区别判断是由原生房源详情跳转还是潜客跳转  原生跳转type 1
            this.potentialResource_id=this.$route.query.prId
            this.roomDisabled=this.$route.query.flag //潜客传入标志
            // debugger
            if(this.$route.query.earnestMoneyInvalidTime == undefined){
              this.findRentByRoomId(this.roomId)
              // this.earnestMoneyInvalidTime= util.dateAddDay(new Date(),3) //定金失效时间
              // this.showEarnestMoneyInvalidTime= util.dateAddDay(new Date(),3) //定金失效时间
            }else{
              this.earnestMoneyInvalidTime= this.$route.query.earnestMoneyInvalidTime //定金失效时间
              this.showEarnestMoneyInvalidTime= this.$route.query.earnestMoneyInvalidTime //定金失效时间
              this.onConfirm(new Date(this.earnestMoneyInvalidTime))
            }

            this.earnestMoney=this.$route.query.earnestMoney //定金
            if(this.roomId){
                this.getRoomHouseListById()//房间初始化
            }
            //潜客传入手机进行用户认证
            if (this.$route.query.flag=='user'){
                this.mobile=this.$route.query.mobile //潜客过来的手机号码
                this.formValidate.renterMobile=this.$route.query.mobile//号码展示
                this.findUserInfoByMobile(this.mobile)
            }else {
                //身份证图片
                this.frontOfCard = this.wtrFrontOfCard
                this.reverseOfCard = this.wtrReverseOfCard
                this.holdingCard = this.entrustment
            }

        },
        methods: {
            showFormatDate(dateTime) {
              console.log(dateTime.getMinutes())
              let year = `${dateTime.getFullYear()}`;
              let month  = `${dateTime.getMonth() + 1}` ;
              let date = `${dateTime.getDate()}` ;
              let hours = `${dateTime.getHours()}` ; ;
              let minutes = `${dateTime.getMinutes()}` ; ;
              if(month < 10){
                month = `0${dateTime.getMonth() + 1}`
              }
              if(date < 10){
                date = `0${dateTime.getDate()}`
              }
              if(hours < 10){
                hours = `0${dateTime.getHours()}`
              }
              if( minutes < 10){
                minutes = `0${dateTime.getMinutes()}`
              }
              return year+ '/' + month+ '/' + date;

            },
            //图片上传前处理
            beforeRead(file) {
                if (file.size==undefined||file.size==''){
                    return false
                }
                return true;
            },
            //关闭弹出层方法
            closeCoOwnerCardShow(){
                this.CardShowImage=false
            },
            // 手机号隐藏四位
            geTel(tel) {
                var reg = /^(\d{3})\d{4}(\d{4})$/
                return tel.replace(reg, '$1****$2')
            },
            //选择人认证
            mobileChange: function (mobile) {
                let that = this
                let initData = {}
                initData.userMobile =mobile
                findUserInfoByMobile(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        let value = response.data.data.data;
                        let nameAuthentication = 0
                        let photo = response.data.data.data.photo
                        if (value && value.nameAuthentication) {
                            nameAuthentication = value.nameAuthentication
                        }
                        that.formValidate.renterNameAuthentication = nameAuthentication
                        console.log("flag=="+that.formValidate.renterNameAuthentication)
                        if (value && value.userName) {
                            that.formValidate.renterNameAuthentication = value.nameAuthentication
                            that.formValidate.renterName = value.userName;
                            that.formValidate.renterMobile=value.mobile
                            that.ownerInfo.name=value.userName;
                            that.formValidate.renterGender = util.getGenderLabel(value.gender);
                            that.ownerInfo.sex=value.gender;
                            if (typeof value.certificateType != 'undefined') {
                                that.formValidate.renterCertificateType = value.certificateType;

                            }
                            that.formValidate.renterCertificateCode = value.certificateCode;
                            that.certificateNumber=value.certificateCode;
                            //身份证图片
                            if (value.fpth) {
                                that.frontOfCard = value.fpth
                            }else{
                                that.frontOfCard = that.wtrFrontOfCard
                            }
                            if (value.bpth) {
                                that.reverseOfCard = value.bpth
                            }else{
                                that.reverseOfCard = that.wtrReverseOfCard
                            }
                            if (value.hpth) {
                                that.holdingCard = value.hpth
                            }else{
                                that.holdingCard = that.entrustment
                            }
                        }
                        that.changeRenterEditState()
                    })
                })
            },
            //选择人是否认证
            changeRenterEditState: function () {
                let that = this
                if (that.formValidate.renterNameAuthentication == 1) {
                    that.changeRenterState(true)
                } else {
                    that.changeRenterState(false)
                }
            },
            // 判断是否能修改信息
            changeRenterState: function (owner) {
                let that = this
                that.formValidate.mobileState = false
                that.formValidate.sexState = owner
                that.formValidate.certificateState = owner
                that.formValidate.nameState = owner
            },


            //查询用户实名认证
            findUserInfoByMobile: function (mobile) {
                let that = this
                let initData = {}
                initData.userMobile =mobile
                findUserInfoByMobile(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        let value = response.data.data.data;
                        // let photo = response.data.data.data.photo
                        let nameAuthentication = 0
                        if (value && value.nameAuthentication) {
                            nameAuthentication = value.nameAuthentication
                        }
                        that.formValidate.renterNameAuthentication = nameAuthentication
                        console.log("flag=="+that.formValidate.renterNameAuthentication)
                        if (value && value.userName) {
                            that.formValidate.renterNameAuthentication = value.nameAuthentication
                            that.formValidate.renterName = value.userName;
                            that.ownerInfo.name=value.userName;
                            that.formValidate.renterGender = util.getGenderLabel(value.gender);
                            that.ownerInfo.sex=value.gender;

                            that.formValidate.renterCertificateCode = value.certificateCode;
                            that.certificateNumber=value.certificateCode;
                            //身份证图片
                            if (value.fpth) {
                                that.frontOfCard = value.fpth
                            }else{
                                that.frontOfCard = that.wtrFrontOfCard
                            }
                            if (value.bpth) {
                                that.reverseOfCard = value.bpth
                            }else{
                                that.reverseOfCard = that.wtrReverseOfCard
                            }
                            if (value.hpth) {
                                that.holdingCard = value.hpth
                            }else{
                                that.holdingCard = that.entrustment
                            }

                            //证件类型及证件号
                            if (typeof value.certificateType != 'undefined') {
                                that.formValidate.renterCertificateType = value.certificateType;
                               setTimeout(() =>{
                                   that.certificateTitle = that.setSelectObj(that.certificateTypeArr,value.certificateType)
                               },100)
                                // this.certificateTitle
                            }
                        }
                        that.setRenterEditState()
                    })
                })
            },
            setSelectObj(arr, value) {
                let dictionaryTitle = ''
                // keyName = keyName || 'value'
                arr.some((item) => {
                    dictionaryTitle = item['dictionaryTitle']
                    return item['dictionaryValue'] == value
                })
                return dictionaryTitle
                // for(let i = 0 ; i < arr.size(); i++){
                //     if (arr[i].dictionaryValue == value) {
                //         dictionaryTitle =arr[i].dictionaryTitle
                //         return dictionaryTitle
                //         break;
                //     }
                // }

            },
            /**
             * 设置租客信息编辑状态
             * 上上签实名认证的用户不能编辑,没有实名认证才可以编辑
             */
            setRenterEditState: function () {
                let that = this
                if (that.formValidate.renterNameAuthentication == 1) {
                    that.setRenterState(true)
                } else {
                    console.log("未认证")
                    that.setRenterState(false)
                }
            },
            //设置租客信息编辑状态
            setRenterState: function (owner) {
                let that = this
                // 潜客进入电话不可修改
                if (that.roomDisabled == "user") {
                    that.formValidate.mobileState = true
                } else {
                    that.formValidate.mobileState = owner
                }
                console.log("owner=="+owner)
                that.formValidate.sexState = owner
                that.formValidate.certificateState = owner
                that.formValidate.nameState = owner
            },
            //获取权限方法
            getStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'potentialUser_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authButtonsList = response.data.data.data
                    })
                })
            },

            //校验方法
            formSubmit:function(){
                if (this.formValidate.renterNameAuthentication==1){
                    //调用保存接口
                    if (this.save()){
                        this.percontractsaveAndUpdatePerContractList();
                    }
                    return
                } else{
                //姓名校验
                let that = this;
                if(that.ownerInfo.name==''||that.ownerInfo.name==undefined){
                    responseUtil.alertMsg(that,"姓名不能为空")
                }else{
                    //姓名汉字正则校验
                    var re = config.NAME_REG;
                    if (!re.test(that.ownerInfo.name)) {
                        responseUtil.alertMsg(that,"请输入正确的姓名")
                    }else{
                        //身份证号码校验
                        if(that.certificateNumber==''||that.certificateNumber==undefined){
                            responseUtil.alertMsg(that,"证件号码不能为空")
                        }else{
                            var cer = config.CERTIFICATE_REG
                            let isMobile =  cer.test(that.certificateNumber);
                            if(!isMobile && that.coOwner.certificateType == '0'){
                                responseUtil.alertMsg(that,"请输入正确的证件号码")
                            }else{
                                //电话号码校验
                                if(that.mobile==''||that.mobile==undefined){
                                    responseUtil.alertMsg(this,"手机号码不能为空")
                                }else{
                                    var phone = config.MOBILE_REG
                                    if (!phone.test(that.mobile)) {
                                        responseUtil.alertMsg(this,"请输入正确的手机号码")
                                    }else{
                                        //调用保存接口
                                        if (this.save()){
                                            this.percontractsaveAndUpdatePerContractList();
                                        }
                                        }
                                }
                            }
                        }
                    }
                }
                }
            },

            // 过滤空格
            formatter(value) {
                return value.replace(/\s*/g,"");
            },

            //租期选择
            clickRentTimeShow(){
                if(this.rentTimeState){
                    this.isRentTimeShow = false
                    responseUtil.alertMsg(this,'业主截止房源不可更改租期！')
                }else{
                    this.isRentTimeShow = true
                }
            },
            //确认租期
            selectRentTime(value) {
                this.rentTimeLong = value.dictionaryValue
                this.countNewRent()
                this.isRentTimeShow = false;
            },
            //根据租期变化租金
            countNewRent(){
                for(let i in this.priceIncreaseSchemeList){
                    if(this.rentTimeLong>=Number(this.priceIncreaseSchemeList[i].lease_termq)&&this.rentTimeLong<=Number(this.priceIncreaseSchemeList[i].lease_termh)){
                      let realPrice = this.priceIncreaseSchemeList[i].priceBenchmark=='2'?this.firstHousePrice:this.offerPrice
                      // let realPrice=Number(this.priceIncreaseSchemeList[i].raise_price)>100?this.firstHousePrice:this.offerPrice
                        this.rentMoney=Math.ceil(realPrice*(Number(this.priceIncreaseSchemeList[i].raise_price)/100))
                        ///标准涨幅   
                        this.percentage=this.priceIncreaseSchemeList[i].raise_price
                        // //是业主到期
                        if(new Date(this.houseEndTime).getTime()<util.dateAddMonth(new Date(),12).getTime()){  
                            this.rentMoney=this.offerPrice
                            this.percentage=100
                        }
                        this.earnestMoney=this.rentMoney
                        break
                    }
                }
            },

            //签约人下拉框选项 进行数据绑定
            clickSignatoryShow() {
                // this.isSignatoryShow = !this.isSignatoryShow;
                // var panel = document.getElementById("signatoryPanel");
                // if (panel) {
                //     document.addEventListener("click", e => {
                //         if (!panel.contains(e.target)) {
                //             this.isSignatoryShow = false;
                //         }
                //     });
                // }
                this.$router.push({
                    name:'referrer',
                    query:{
                        type: 1,
                    }
                })

            },

            //签约人下拉框选项 进行数据绑定
            selectSignatoryOption(item) {
                this.staffName= item.staffName;
                this.signDepartment_id=item.department_id//签约人部门id
                this.signStaff_id=item.id
                this.isSignatoryShow = false;
                this.isSignatorySelected = true;
            },

            //合作人
            clickPartnerShow() {
                // this.isPartnerShow = !this.isPartnerShow;
                // let panel = document.getElementById("partnerPanel");
                // if (panel) {
                //     document.addEventListener("click", e => {
                //         if (!panel.contains(e.target)) {
                //             this.isPartnerShow = false;
                //         }
                //     });
                // }

                this.$router.push({
                    name:'referrer',
                    query:{
                        type: 2,
                    }
                })
            },

            clickModileShow() {
                // this.isPartnerShow = !this.isPartnerShow;
                // let panel = document.getElementById("partnerPanel");
                // if (panel) {
                //     document.addEventListener("click", e => {
                //         if (!panel.contains(e.target)) {
                //             this.isPartnerShow = false;
                //         }
                //     });
                // }

                this.$router.push({
                    name:'referrer',
                    query:{
                        type: 3,
                    }
                })
            },
            chooseUser(){
                if (this.roomDisabled=='user'){
                    return ''
                } else{
                    return this.clickModileShow();
                }

            },
                //合作人进行数据绑定
            selectPartnerOption(item) {
                this.label = item.staffName;
                this. partnerDepartment_id=item.department_id
                this.partnerStaffId=item.id
                this.isPartnerShow = false;
                this.isPartnerSelected = true;
            },

            //身份证选择框
            clickCoOwnerCardShow() {
                this.CardShowImage=true
                this.isCoOwnerCardShow = !this.isCoOwnerCardShow;
            },

            //手持身份证
            afterReadCertificateHandHeld(file) {

                let that = this
                let initData = {
                    base64: file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code==0){
                            that.holdingCard = response.data.data.path
                        } else if(response.data.code!=0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else {
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },

            changUpload(name){
                this.$refs[name].chooseFile()
            },

            //身份证正面
            afterReadCoOwnerCertificateFront(file) {
                let that = this
                let initData = {
                    base64: file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code==0){
                            that.frontOfCard = response.data.data.path
                        } else if(response.data.code!=0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else {
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },

            //身份证背面
            afterReadCoOwnerCertificateBack(file) {
                let that = this
                let initData = {
                    base64: file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code==0){
                            that.reverseOfCard = response.data.data.path
                        } else if(response.data.code!=0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else {
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },

            //性别选择
            mansex(){
                this.ownerInfo.sex = '1'
            },
            womansex(){
                this.ownerInfo.sex = '0'
            },


            //合作占比下拉框
            showOrHiddenOfBankType() {
                this.isBankTypeSelect = !this.isBankTypeSelect;
            },
            //合作占比
            selectBankType(item) {
                this.normaltext = item.dictionaryTitle;
                this.partnerRatioId=item.id
                this.isBankTypeSelect = false;
                this.isBankTypeSelectAready = true;
            },

            //定金失效时间
            onConfirm(date){
                this.show=false
                this.earnestMoneyInvalidTime=this.formatDate(date)
                this.showEarnestMoneyInvalidTime=this.showFormatDate(date)
            },
            //关闭时间选择
            cancel(){
                this.show=false
            },

            //获取身份证类型和名称
            selectCoOwnerCard(item) {
                this.certificateType=item.dictionaryValue//证件类型
                this.certificateTitle=item.dictionaryTitle//证件名称
                this.CardShowImage=false
                this.isCoOwnerCardShow = false;
        },

            //时间处理方法
            formatDate(date){
                let month = date.getMonth() >=10 ? date.getMonth()+1 :'0' +(date.getMonth()+1)
                let day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
                return   `${date.getFullYear()}-${month}-${day}`;
            },

            //返回上一层
            leftReturn(){
                if(openInWebview()){
                    //APP传值*******begin*********
                    if(checkAndroid()){
                        backToAndroid();
                    }else if(checkIOS()){
                        window.webkit.messageHandlers.closeWindow.postMessage({});
                        // console.log("对ios传参，调用IOS关闭页面方法,zheshi");
                    }
                    //APP传值*******end*********
                }else{
                    this.$router.go(-1);
                }

            },
            //返回上一层
            leftReturnA(){
                this.$router.go(-2);
            },

            //点击下拉框区域外 下拉框隐藏
            // clickCohabitingPersonCardShow() {
            //     this.isCohabitingPersonCardShow = !this.isCohabitingPersonCardShow;
            //     var panel = document.getElementById('cohabitingPersonCardPanel')
            //     if(panel){
            //         document.addEventListener('click',e =>{
            //             if(!panel.contains(e.target)){
            //                 this.isCohabitingPersonShow = false
            //             }
            //         })
            //     }
            // },

            //签约人接口
            initStaffData() {
                var that = this;
                queryStaffList({}).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.signatoryArr = response.data.data.data;
                        let staffInfo  = that.getArrValuesByIndex(that.signatoryArr,getStaffId())
                        that.staffName= staffInfo.staffName;
                        that.signDepartment_id=staffInfo.department_id//签约人部门id
                        that.signStaff_id=staffInfo.id

                        that.partnerArr = util.deepClone(that.signatoryArr)
                        that.partnerArr.unshift({
                            department_id: '-1',
                            id: '',
                            staffName: "无",
                            title: "无",
                            value: -1
                        });
                    });
                });
            },

            //根据id或者value 获取对应数组的值
            getArrValuesByIndex(arr,index){
                for(let i=0;i<arr.length;i++){
                    if(arr[i].value==index){
                        return arr[i]
                    }
                }
                return ''
            },
            // 房间信息（查询定金时间）
            findRentByRoomId(){
              let that =this;
              let _parms = {
                roomId:that.roomId
              }
              getPerRoomHouseList(_parms).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                  // debugger
                  that.earnestMoneyInvalidTime =  util.dateAddDay(new Date(),Number(response.data.data.defaultPerContractDays));
                  that.showEarnestMoneyInvalidTime =  util.dateAddDay(new Date(),Number(response.data.data.defaultPerContractDays));
                  // if(response.data.data.subType == '8' && response.data.data.vacantDate){
                  //   that.earnestMoneyInvalidTime = util.dateAddDay(response.data.data.vacantDate,Number(that.defaultPerContractDays))
                  //   that.showEarnestMoneyInvalidTime = util.dateAddDay(response.data.data.vacantDate,Number(that.defaultPerContractDays))
                  // }
                  that.onConfirm(new Date(that.earnestMoneyInvalidTime))

                })
              })
            },
            //新增预约初始化接口
            getRoomHouseListById(){
                let that =this;
                let queryDate = {
                    roomId : that.roomId
                }
                findRentByRoomId(queryDate).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                            console.log(response.data.data)
                            that.offerPrice = response.data.data && parseInt(response.data.data.offerPrice) || '-';  //报价
                            that.rentMoney = response.data.data && parseInt(response.data.data.offerPrice) || '-';  //没选择租期的时候，租金默认报价
                            that.firstHousePrice = response.data.data && parseInt(response.data.data.firstHousePrice) || '-';  //首次出房价
                            that.priceIncreaseSchemeList = response.data.data.priceIncreaseSchemeList  //提价区间
                            that.houseEndTime = response.data.data.houseEndTime  //房源截止时间
                            that.rentTimeState = false
                            if(new Date(that.houseEndTime).getTime()<util.dateAddMonth(new Date(),12).getTime()){
                                that.rentTimeState = true  //不可更改租期
                                that.rentTimeLong = Math.floor((new Date(that.houseEndTime).getTime() - new Date().getTime())/(24*3600*1000*30))
                            }
                            that.countNewRent()
                        }else if (response.data.code != 0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else{
                            responseUtil.alertMsg(that,response.data.msg)
                        }

                    })
                })
                let _parms = {
                    room_id:that.roomId
                }
                getRoomHouseListById(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                            that.roomHouseList=response.data.data.data //房间信息
                            if (response.data.data.data[0].offerPrice){
                                // that.earnestMoney=response.data.data.data[0].offerPrice,//定金
                                // that.rentMoney=response.data.data.data[0].offerPrice//租金
                                that.houseChildTypeName=response.data.data.data[0].houseChildTypeName//房间状态
                            }else {
                                // that.earnestMoney='',
                                // that.rentMoney=''
                                that.houseChildTypeName=''
                            }
                        }else if (response.data.code != 0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else{
                            responseUtil.alertMsg(that,response.data.msg)
                        }

                    })
                })
            },

            //字典表接口
            getQueryBaseData(){
                let that =this;
                let _parms = {
                    fdName:that.fdName,
                    dbName:["contractRaito"],
                }
                getQueryBaseData(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                            that.certificateTypeArr=response.data.data.CERTIFICATETYPEMAP//证件类型
                            that.contractRaito=response.data.data.contractRaito//合同占比
                        }else if (response.data.code != 0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else{
                            responseUtil.alertMsg(that,response.data.msg)
                        }

                    })
                })
            },
            //字典项获取租期列表
            getRenterData(){
                let that=this
                let initData={}
                queryMonth(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.rentTimeLongList=response.data.data.data
                    })
                })
            },

            //可以提交保存判断
            save(){
            if (
                (this.ownerInfo.emergencyContactPhone==''? this.mobile:this.ownerInfo.emergencyContactPhone)&&
                (this.ownerInfo.name==''? this.formValidate.renterName: this.ownerInfo.name)&&
                (this.ownerInfo.sex==''? this.formValidate.renterGender:this.ownerInfo.sex)&&
                (this.certificateType==''? this.formValidate.renterCertificateType:this.certificateType)&&
                (this.certificateNumber==''?this.formValidate.renterCertificateCode:this.certificateNumber)&&
                this.rentTimeLong&&
                this.earnestMoney&&
                // this.frontOfCard &&
                // this.reverseOfCard&&
                // this.holdingCard&&
                this.earnestMoneyInvalidTime&&
                // this.remarks&&
                this.saveFlag&&
                this.staffName&&
                (!this.label? true:this.normaltext==''?false:this.normaltext)

            ){
                return true
            }
            return false
            },

            //预约合同新添加接口
            percontractsaveAndUpdatePerContractList(){
                var reg = /^[1-9]\d*$/;
                if (!reg.test(this.earnestMoney)) {
                    responseUtil.alertMsg(this,'定金请填写正整数')
                    return true;
                }
                let that =this;
                let _parms = {
                    id:that.contractId,//合同id
                    addStaff_id:getStaffId(),//创建人员工id
                    mobile:that.ownerInfo.emergencyContactPhone==''? that.mobile : that.ownerInfo.emergencyContactPhone,//手机号码
                    userName:that.formValidate.renterNameAuthentication==1? that.formValidate.renterName:that.ownerInfo.name ,//用户姓名
                    gender: that.ownerInfo.sex,//性别
                    certificateType:that.formValidate.renterNameAuthentication==1?that.formValidate.renterCertificateType:that.certificateType,//证件类型
                    certificateCode:that.formValidate.renterNameAuthentication==1?that.formValidate.renterCertificateCode:that.certificateNumber,//身份证号码
                    roomHouse_id:that.roomId,//间房源id
                    signStaff_id:that.signStaff_id,//签约人员工id
                    partnerStaffId:that.partnerStaffId,//合作人员工id
                    partnerRatioId:that.partnerRatioId,//合作占比id
                    signDepartment_id:that.signDepartment_id,//签约人部门id
                    partnerDepartment_id:that.partnerDepartment_id,//合作人部门id
                    rentMoney:that.rentMoney,//预定时租金
                    rentTimeLong:that.rentTimeLong,//租期
                    earnestMoney:that.earnestMoney,//定金
                    earnestMoneyInvalidTime:that.earnestMoneyInvalidTime,//定金失效时间
                    houseChildTypeName:that.houseChildTypeName,//状态
                    remarks:that.remarks,//备注
                    potentialResource_id:that.potentialResource_id,//潜在房源id
                    frontOfCard:that.frontOfCard==that.wtrFrontOfCard? '':that.frontOfCard,//正面
                    reverseOfCard:that.reverseOfCard==that.wtrReverseOfCard? '':that.reverseOfCard,//反面
                    holdingCard:that.holdingCard==that.entrustment? '':that.holdingCard,//手持身份证
                }
                if(!that.saveFlag) return
                that.saveFlag = false
                that.isDisable=true
                setTimeout(()=>{
                percontractsaveAndUpdatePerContractList(_parms).then(function (response) {
                    that.saveFlag = true
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                                that.isDisable=false
                                responseUtil.alertMsg(that,response.data.msg)
                            if(that.$route.query.flag=='user'){
                                that.leftReturnA();
                            }else{
                                setTimeout(()=>{
                                    that.leftReturn();   //交互返回
                                },2000)
                            }
                        }else if (response.data.code != 0){
                            that.saveFlag = true
                            responseUtil.alertMsg(that,response.data.msg)
                        }else{
                            responseUtil.alertMsg(that,response.data.msg)
                        }

                    })
                })
                },2000)
            },
            //监听身份证信息
            animateOwnerIdCard() {
                var re = config.CERTIFICATE_REG
                let str = this.certificateNumber
                if(this.certificateType == 0){
                    if(str != ''){
                        if (!re.test(str)) {
                            responseUtil.alertMsg(this,'租客身份证格式不正确')
                            // this.ownerInfo.ce.part-inputpart-cardpic-picrtificateCode = "";
                        }else{
                            this.ownerInfo.sex = (str.substr(-2, 1) % 2) + ""
                        }
                    }

                }

            },
        }
    }
</script>


<style lang="less" scoped>
    .newReserve{
        padding-bottom: 30px;
        overflow: hidden;
    }
    .rentTimeLongClass{
        border-top: 1px solid #efefef;

    }
    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }
    .part {
        margin: 15px;
    }
    .part-button {
    height: 45px;
    border-radius: 10px;
    background-color: #ededed;
    color: #ffffff;
    text-align: center;
    line-height: 45px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 50px;
}

    .part-inputpart-dropDownList-option {
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }
    .youjiantouImg{
        width: 5px;
        height: 9px;
        margin-right: 15px;
    }
    .youjiantouImgDiv{
        width: 5px;
        height: 9px;
        margin-right: 15px;
        /*position: absolute;*/
        /*right: 15px;*/
        z-index: 99;
        line-height: 9px;
        /*top: 1px;*/

    }

    .youjiantou{
        width: 100%;
        height: 100%;
    }
    .part-inputpart-cardpic {
        margin-top: 15px;
        border-radius: 8px;
        background-color: white;
    }
    .papers{
        padding: 20px 0px 0px 30px;
        font-weight: 600;
    }
    .part-inputpart-cardpic-row {
        margin: 0;
    }

    .part-inputpart-cardpic-module {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .part-inputpart-cardpic-module-1 {
        display: flex;
        padding-top: 20px;
        flex-direction: row;
        justify-content: center;
    }

    .part-cardpic-module {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .part-inputpart-cardpic-pic {
        width: 85.6px;
        height: 54px;
        border-radius: 5px;
        margin: 0 15px;
        background-color: #e1eaec;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;

        }

    }

    .part-inputpart-cardpic-button {
        display: inline-block;
        background: url("../../../assets/images/grayAdd.png") center center no-repeat;
        width: 85.6px;
        height: 54px;
        border-radius: 5px;
        background-size: 30px;
        background-color: #fafafa;
        margin: 0 15px;
    }

    .part-inputpart-cardpic-text {
        font-size: 12px;
        color: #d8d8d8;
        margin: 7px 0 13px;
    }
    .p_span{
        color: #9D9D9D;
        font-size: 12px;
        font-weight: 400;
        font-family: PingFangSC-Regular;
    }
    .part-inputpart-row-normaltext {
        color: black;
        width: 100%;
    }
    .changeCause{
        width: 100%;
        height: 185px;
        background-color: #FFFFFF;
        border-radius: 8px;
        .textareaTitle{
            display: flex;
            height: 45px;
            line-height: 45px;
            align-items: center;
        }
        .textareaInput{
            display: block;
            width: 84%;
            margin: -3px auto 20px;
            height: 90px;
            border-radius: 8px;
            background-color: #f7f7f7;
            border: none;
            resize: none;
            padding: 15px;
            font-size: 14px;
        }
    }
    .borderStyle{
        width: 100%;
        margin: 0 auto;
        display: flex;
       border: 8px;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        justify-content: left;
        font-size: 15px;

    }
    .ver{
        margin-left: 10px;
        margin-right: 10px;
        font-size: 15px;
        color:#999999 ;
    }
    .pOne{
        font-size: 15px;
        font-weight: bold;
    }
    .borderType{
        border-top: 1px solid rgba(239, 239, 239, 1);
        border-bottom: 1px solid rgba(239, 239, 239, 1);
    }
    .changeType {
        width: 100%;
        height: 50px;
        display: flex;
        background-color: #FFFFFF;
        align-items: center;
        .changeTypeImg{
            width: 25px;
            height: 25px;
            margin-right: 15px;
        }
        .vanCell{
            /*height: 40px;*/
            /*line-height: 40px;*/
            margin-left: 5px;
            width: 56%;
            border: none;
            font-size: 15px;
            color: #FF5D3B;
        }
        select{
            width: 215px;
            height: 40px;
            border: none;
            background-color: #FFFFFF;
            margin-bottom: 5px;
        }
    }
    .genderTitle{
        font-weight: 600;
        color:rgba(34, 34, 34, 1);
    }
    .gender{
        width: 153px;
    }
    .tenant{
        padding: 20px 0px 15px 15px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        color: rgba(34, 34, 34, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
    }
    .tenant span{
        margin-right: 10px;
    }
    .tenant div{
        height: 24px;
        color: rgba(216, 216, 216, 1);
    }
    .tenantUsername{
        display: flex;
        align-items: center;
        justify-content: left;

    }
.username{
    width: 100%;
    height: 85%;
    margin-left: 0px;
    background-color: #ffffff;
    border: none;
}
    .nav-bar{
        background-color: white;
        border: 0;
    }

    .headbar{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 40px;
        width: 100%;
        font-size: 15px;
        font-weight: 900;
        padding-top: 10px;
    }

    .headbar-fixed{
        background-color: white;
        position: fixed;
        top: 50px;
        z-index: 999;
    }

    .headbar-redline{
        height: 4px;
        width: 20px;
        border-radius: 3px;
        background-color: #ff5d3b;
        margin: 5px 0 0 20px;
    }

    .headbar-graytick{
        display: inline-block;
        background: url("../../../assets/images/grayTick.png");
        width: 18px;
        height: 18px;
        background-size: 18px;
        margin-bottom: -3px;
    }

    .headbar-greentick{
        display: inline-block;
        background: url("../../../assets/images/GrassgreenTick.png");
        width: 18px;
        height: 18px;
        background-size: 18px;
        margin-bottom: -3px;
    }

    .contentpart{
        margin-top: 80px;
    }

    .part {
        width: 92%;
        margin: 0 auto;
    }

    .other-part{
        margin: 0 15px 50px 15px;
    }

    .other-part-add{
        top: 42px
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
        position: relative;
        top: 12px;
    }

    .part-inputpart {
        width: 92%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        justify-content: left;
        font-size: 15px;
    }


    .part-inputpart div:last-child{
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 50px;
        background-color: #ffffff;
        margin-top: 15px;
    }

    .part-inputpart-row-short{
        width: 60%;
        float: left;
    }
    .part-inputpart-row-shortA{
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 50px;
        background-color: #ffffff;
        width: 100%;
    }


    .part-inputpart-row-short-graytext{
        color: #d8d8d8;
    }

    .part-inputpart-row-header{
        color: rgba(34, 34, 34, 1);
        font-size: 15px;
        font-weight: 900;
        margin-right: 10px;
    }
    .part-inputpart-row-header-pay{
        color:rgba(199, 199, 199, 1);
        font-size: 15px;
        margin-right: 21px;
    }
    .part-inputpart-row-sex{
        margin-top: 26px;
    }


    .part-inputpart-man{
        margin-left: 25px;
        margin-right: 18px;
    }

    .part-inputpart-woman{
        margin-left: 18px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
    }

    .part-inputpart-row-normaltext{
        color: black;
        width: 100%;
    }

    .part-inputpart-row-redtext{
        width: 100%;
        color: #ff5d3b;
    }


    .part-inputpart-row-right{
        float: right;
        margin-right: 10px;
    }

    .part-inputpart-row-right-downArrow{
        margin-left:10px;
        width: 9px;
    }

    .part-inputpart-row-right-upArrow{
        width: 9px;
        margin-left:10px;
        transform: rotateX(180deg);
    }
    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
    }
    .part-inputpart-dropDownList {
        height: 23%;
        /*垂直方向滚动*/
        overflow-y: scroll;
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }
    .content-have {
        padding: 3px;
        margin: 0 15px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 15px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }
    .content-noneAA{
        padding: 3px;
        margin: 0 15px;

    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin-left:10px ;
        margin-right: 5px;
    }

    .content-divide-position{
        position: relative;
        bottom: 2px;
    }

    @border:2px solid rgb(244, 244, 244);
    .title{
        width: 100%;
        height: 110px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

    }

    /*超出隐藏部分div的css*/
    .hidden{
        width: 210px;
        /*height: 20px;*/
        font-weight: bold;
        font-family: PingFangSC-Semibold;
        font-size: 15px;
        color: #222222
    }
    .relation{
        width: 100px;
        height: 75px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .relationImage{
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }

    /*沙河口部分的css margin-right: 8px;font-size: 13px*/
    .font{
        margin-right: 8px;
        font-size: 13px;

    }
    .fnotRed{
        font-size: 13px;
        color: rgba(255, 95, 62, 1);
        margin-right: 5px;
    }
    .panelInfo{
        border-bottom:@border;
        border-top:@border;
        font-size: 14px;

    }
    .bottom .look{
        width: 90px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        line-height: 32px;
        margin-left: 42px;
        color: #fe5e3a;
        border-radius: 8px;
        border: 1px solid transparent;
        background-image: linear-gradient(white,white),linear-gradient(to right,#FFBB70,#FF7449);
        background-clip: padding-box,border-box;
        background-origin: border-box;
    }
    //文字背景
    .bottom .check{
        width: 80px;
        height: 30px;
        font-size: 12px;
        padding-top: 0.05rem;
        text-align: center;

        line-height: 30px;
        color: #F7DFC8;
        margin-left: 42px;
        /*position: center;*/

        margin-top: 20%;
        margin-bottom: 20%;
        border-radius: 8px;
        background: linear-gradient(to right,#FFBB70,#FF7449);
    }
    .head{
        width: 100%;
        height: 20px;
        z-index: 8;
        background: #f8f8f8;
    }
    .search{
        margin: 60px 20px 0px 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .navbar{
        z-index: 10;
        background-color: #f8f8f8;
    }

    input{
        font-size: 14px;
        margin-left: 15px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: #d9d9d9;
    }
    .reviews{
        width: 100%;
        height: auto;
        padding-bottom: 80px;
        margin-top: 5px;

    }
    .review{
        width: 92%;
        margin:0 auto;
        height: auto;
        background-color: white;
        border-radius: 8px;
    }
    .bottom{
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;

    }
    .bottom .time{
        width: 180px;
        height: 20px;
        text-align: left;
        line-height: 20px;
        font-size: 14px;
        color: #999999;
        margin-left: 15px;
        white-space: nowrap;
    }
    .panelInfoP{
        color: rgba(34, 34, 34, 1);
        font-weight: 600;
        padding-left: 20px;
    }
    .customera{
        width: 92%;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 5px;
    }
    .customer{
        width: 92%;
        margin: 0 auto;
        margin-top: 21px;
        margin-bottom: 5px;

    }

    .customer,.customera>span{
        color: rgba(255, 93, 59, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .customerSpan{
        margin-left: 5px;
    }

    /*占位span*/
    .site-span {
        width: 50px;
        height: 10px;
    }

    .partInputpartRowNormaltext{
        color: black;
        width: 100%;
    }

</style>
